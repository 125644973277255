import api from '@/services/api'

const RESOURCE_URI = '/store'

const fetchStores = () => api.get(RESOURCE_URI)
const fetchStoreById = (id) => api.get(`${RESOURCE_URI}/${id}`)
const createStore = (store) => api.post(RESOURCE_URI, store)
const updateStore = (store) => api.put(`${RESOURCE_URI}/${store.id}`, store)
const removeStore = (id) => api.delete(`${RESOURCE_URI}/${id}`)
const setMain = (store) => api.post(`${RESOURCE_URI}/${store.id}/main`)
const fetchCurrentTime = () => api.get(`${RESOURCE_URI}/current-time`)
const changeStore = (store) =>
  api.post(`${RESOURCE_URI}/changeStore`, { storeId: store })

export default {
  fetchStores,
  fetchStoreById,
  createStore,
  updateStore,
  removeStore,
  setMain,
  changeStore,
  fetchCurrentTime
}
