import api from '@/services/api'

const state = {
  currentTime: null,
  timerInterval: null,
  visibilityListenerSetup: false
}

const getters = {
  formattedTime: (state) => {
    return state.currentTime.toISOString()
  }
}

const actions = {
  fetchServerTime({ state, commit, dispatch }) {
    if (state.currentTime === null) {
      api
        .get('/store/currentTime')
        .then((data) => {
          commit('setServerTime', new Date(data.utcTime))
          commit('startTimer')
          if (!state.visibilityListenerSetup) {
            dispatch('setupVisibilityChangeListener')
            commit('setVisibilityListenerSetup', true)
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar o horário do servidor:', error)
        })
    }
  },
  setupVisibilityChangeListener({ dispatch }) {
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'hidden') {
        window.myTimeout = setTimeout(() => {
          window.userWasAway = true
        }, 10000)
      } else if (document.visibilityState === 'visible') {
        clearTimeout(window.myTimeout)
        if (window.userWasAway) {
          window.userWasAway = false
          // console.log('Usuario ficou fora, nova request')
          dispatch('fetchServerTimeAgain')
        }
      }
    })
  },
  fetchServerTimeAgain({ commit }) {
    api
      .get('/store/currentTime')
      .then((data) => {
        commit('setServerTime', new Date(data.utcTime))
      })
      .catch((error) => {
        console.error('Erro ao atualizar o horário do servidor:', error)
      })
  }
}

const mutations = {
  setServerTime(state, time) {
    state.currentTime = time
  },
  startTimer(state) {
    if (!state.timerInterval) {
      state.timerInterval = setInterval(() => {
        state.currentTime = new Date(state.currentTime.getTime() + 1000)
        // console.log('=================')
        // console.log(new Date())
        // console.log('Data PC')
        // console.log('Data SyS')
        // console.log(state.currentTime)
        // console.log('=================')
      }, 1000)
    }
  },
  setVisibilityListenerSetup(state, value) {
    state.visibilityListenerSetup = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
