import { omitBy, isNil } from 'lodash/fp'
import { HTTP_MULTIPART_CONFIG } from '@/constants'
import { parseMultiPartFormData } from '@/helpers/misc'
import api from '@/services/api'

const RESOURCE_URI = '/toy'

const fetchAllToys = () => api.get(`${RESOURCE_URI}/all`)
const fetchToys = ({ storeId } = {}) => {
  const query = new URLSearchParams(omitBy(isNil, { storeId })).toString()

  return api.get([RESOURCE_URI, query].join('?'))
}
const fetchToyById = async (id) => {
  try {
    // Faz a chamada GET para a API usando a biblioteca `api` e o ID do brinquedo
    const response = await api.get(`${RESOURCE_URI}/${id}`)
    console.log(response)
    // Retorna a resposta da API
    return response
  } catch (error) {
    // Trata erros que podem ocorrer durante a chamada à API
    console.error('Erro ao buscar brinquedo:', error)
    throw error
  }
}

const createToy = (toy) => {
  const formData = parseMultiPartFormData(toy)
  return api.post(RESOURCE_URI, formData, HTTP_MULTIPART_CONFIG)
}

const updateToy = (toy) => {
  const formData = parseMultiPartFormData(toy)
  return api.put(`${RESOURCE_URI}/${toy.id}`, formData, HTTP_MULTIPART_CONFIG)
}
const removeToy = (id) => api.delete(`${RESOURCE_URI}/${id}`)
const fetchToysRents = () => api.get(`${RESOURCE_URI}/rents`)

export default {
  fetchToys,
  fetchAllToys,
  fetchToyById,
  createToy,
  updateToy,
  removeToy,
  fetchToysRents
}
